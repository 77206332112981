import md5 from 'md5';
import { Suspense, lazy, useEffect, useState } from 'react';
import { Loader } from './components/Loader';
import { NextPageProvider } from './contexts/NextPageProvider';
// import { SplashScreen } from "./components/SplashScreen";
// import { AnimatePresence } from "framer-motion";

const RealApp = lazy(async () => await import('./RealApp'));

const targetAppEnableValue = '0ad307031b6b537bdb89456515fa5fc4';

const App = (): JSX.Element => {
  const searchParams = new URLSearchParams(location.search);
  const urlParam = searchParams.get('app');

  // const [initializing, setInitializing] = useState(true);
  const [appEnabled, setAppEnabled] = useState(
    md5(localStorage.getItem('appKey') ?? '') === targetAppEnableValue ||
      urlParam === 'android' ||
      urlParam === 'ios'
  );

  useEffect(() => {
    // Disable scroll restoration
    if ('scrollRestoration' in history) {
      history.scrollRestoration = 'manual';
    }
  }, []);

  /*
  const [initialWait, setInitialWait] = useState(true);
  const loading = initializing || initialWait;

  useEffect(() => {
    setTimeout(() => {
      setInitialWait(false);
    }, 1000);
  }, []);
  */

  return appEnabled ? (
    <>
      <Suspense
        fallback={
          <div className="flex items-center justify-center w-screen h-screen">
            <Loader />
          </div>
        }
      >
        <NextPageProvider>
          <RealApp
            onReady={() => {
              // setInitializing(false);
            }}
          />
        </NextPageProvider>
      </Suspense>
      {/* <AnimatePresence mode="wait">
        <div key={"" + loading}>{loading && <SplashScreen />}</div>
      </AnimatePresence> */}
    </>
  ) : (
    <div className="p-4">
      <p>Work in progress...</p>
      <input
        type="password"
        onChange={(e) => {
          console.log(e.target.value, md5(e.target.value));
          if (md5(e.target.value) === targetAppEnableValue) {
            setAppEnabled(true);
            localStorage.setItem('appKey', e.target.value);
          }
        }}
      />
    </div>
  );
};

export default App;
